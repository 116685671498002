<template lang="pug">
.container.min-h-screen
  baseHeading ChargerBack Control Panel
  .grid.grid-cols-3.gap-4
    baseSelect(
      :options="optionsMonths",
      v-on:setOption="changeMonth",
      :optionSelected="monthSelected"
    )
    baseSelect(
      :options="optionsYears",
      v-on:setOption="changeYear",
      :optionSelected="yearSelected"
    )
    baseButton(size="normal", @click="reloadData") Search

  .grid.grid-cols-2.gap-4.mt-8
    .box-cards.bg-blue-600
      .flex.items-center
        svgIcon.mr-2(:path="mdiCreditCard", type="mdi", :size="24")
        .title Visa
      .h-px.bg-blue-400
      .py-4
        .flex.justify-between
          .sub-title Payments
          .result {{ creditCardVisa?.payments || 0 }}
        .flex.justify-between
          .sub-title Chargerbacks
          .result {{ creditCardVisa?.chargerbacks || 0 }}
        .flex.justify-between
          .sub-title ChargerBack Ratio
          .result {{ creditCardVisa?.disputeRatio || 0 }}%
    .box-cards.bg-yellow-600
      .flex.items-center
        svgIcon.mr-2(:path="mdiCreditCard", type="mdi", :size="24")
        .title Mastercard
      .h-px.bg-yellow-400
      .py-4
        .flex.justify-between
          .sub-title Payments
          .result {{ creditCardMasterCard?.payments || 0 }}
        .flex.justify-between
          .sub-title Chargerbacks
          .result {{ creditCardMasterCard?.chargerbacks || 0 }}
        .flex.justify-between
          .sub-title ChargerBack Ratio
          .result {{ creditCardMasterCard?.disputeRatio || 0 }}%
  dataTable.mt-8(
    :rows="dataFormated",
    :headings="headings",
    :options="options",
    ref="tableRef"
  )
</template>
<script>
import { ref, computed } from "vue";
import { mdiCreditCard } from "@mdi/js";
import AnalyticsRepository from "@/api/analytics.repository";
import dataTable from "@/components/dataTables/dataTable";
import * as countriesName from "i18n-iso-countries";

export default {
  name: "ChargerbacksControl",
  components: {
    dataTable,
  },
  data: () => ({
    mdiCreditCard,
    optionsCard: [
      { name: "All", id: "all" },
      { name: "Visa", id: "visa" },
      { name: "Mastercard", id: "mastercard" },
    ],
    optionsYears: [
      {
        name: "2021",
        id: 2021,
      },
      {
        name: "2022",
        id: 2022,
      },
      {
        name: "2023",
        id: 2023,
      },
      {
        name: "2024",
        id: 2024,
      },
      {
        name: "2025",
        id: 2025,
      },
    ],
    optionsMonths: [
      {
        name: "January",
        id: 0,
      },
      {
        name: "February",
        id: 1,
      },
      {
        name: "March",
        id: 2,
      },
      {
        name: "April",
        id: 3,
      },
      {
        name: "May",
        id: 4,
      },
      {
        name: "June",
        id: 5,
      },
      {
        name: "July",
        id: 6,
      },
      {
        name: "August",
        id: 7,
      },
      {
        name: "September",
        id: 8,
      },
      {
        name: "October",
        id: 9,
      },
      {
        name: "November",
        id: 10,
      },
      {
        name: "December",
        id: 11,
      },
    ],
    headings: ["Country", "Payments", "Chargerbacks", "Ratio %", "Card Brand"],
    options: {
      perPage: 1000,
      perPageSelect: false,
      fixedHeight: false,
      footer: true,
      searchable: true,
    },
  }),
  setup() {
    const actualMonth = new Date().getMonth();
    const actualYear = new Date().getFullYear();
    const monthSelected = ref(actualMonth);
    const yearSelected = ref(actualYear);
    const dataCreditCards = ref([]);
    countriesName.registerLocale(require("i18n-iso-countries/langs/en.json"));
    const tableRef = ref(null);
    const countriesData = ref([]);

    const changeMonth = (monthId) => (monthSelected.value = monthId);
    const changeYear = (yearId) => (yearSelected.value = yearId);

    const getDataCards = async () => {
      const { data } = await AnalyticsRepository.chargerBackAnalytics(
        monthSelected.value,
        yearSelected.value,
        "cardType"
      );
      dataCreditCards.value = data.analytics;
    };

    const getDataCountries = async () => {
      const { data } = await AnalyticsRepository.chargerBackAnalytics(
        monthSelected.value,
        yearSelected.value,
        "country"
      );
      countriesData.value = data.analytics;
      console.log(data);
    };

    const creditCardVisa = computed(() => {
      return findCreditCardBrand("VISA");
    });
    const creditCardMasterCard = computed(() => {
      return findCreditCardBrand("MASTERCARD");
    });

    const findCreditCardBrand = (brandName) => {
      if (dataCreditCards.value) {
        return dataCreditCards.value.find(
          (card) => card.card_type == brandName
        );
      } else {
        return {};
      }
    };

    const dataFormated = computed(() => {
      return countriesData.value.map((b) => {
        return {
          c: `(${b.country}) ${countriesName.getName(b.country, "en", {
            select: "official",
          })}`,
          payments: `${b.payments}`,
          chargerbacks: `${b.chargerbacks}`,
          disputeRatio: `${b.disputeRatio} %`,
          card_type: b.card_type,
        };
      });
    });

    const reloadData = async () => {
      getDataCards();
      getDataCountries();
    };

    reloadData();

    return {
      monthSelected,
      yearSelected,
      changeMonth,
      changeYear,
      creditCardVisa,
      creditCardMasterCard,
      dataFormated,
      tableRef,
      countriesData,
      reloadData,
    };
  },
};
</script>
<style lang="scss" scoped>
.box-cards {
  @apply flex rounded-lg p-4 flex-col;

  .title {
    @apply text-2xl font-bold;
  }

  .sub-title {
    @apply font-bold text-blue-200;
  }
}
</style>