import { ApiAxios } from './api.config';

const RESOURCE = '/__admin/analytics';

export default {
  async basicAnalytics() {
    return await ApiAxios().get(`${RESOURCE}`);
  },
  async globalAnalytics() {
    return await ApiAxios().get(`${RESOURCE}/global`);
  },
  async countryAnalytics(period) {
    return await ApiAxios().get(`${RESOURCE}/country`, {
      params: {
        period
      }
    });
  },

  async chargerBackAnalytics(month, year, groupBy) {
    return await ApiAxios().get(`${RESOURCE}/chargerbacks`, {
      params: {
        month,
        year,
        groupBy
      }
    });
  }
};
